<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">SA8000社会责任管理体系审核</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <div class="PList">
        <p style="text-indent: 2em">
          SA8000社会责任管理体系，是全球首个道德规范国际标准，为企业的社会责任管理提供一套成熟有效的管理模式。其宗旨是确保供应商所供应的产品，皆符合社会责任标准的要求。
          SA8000每年必须由认证机构进行一次审核以证明企业的管理体系有持续运行。在认证机构审核前，企业应进行一次内部审核。
        </p>
        <p>我司经验丰富的咨询顾问将现场指导企业内部审核，其意义在于：</p>
        <p>①发现体系运行缺陷，确保顺利通过认证机构审核。</p>
        <p>②为企业持续有效运行体系提供建设性建议。</p>
        <p>③提高企业员工体系维护的能力。</p>
      </div>
      <br />
      <br />
      <p class="PTitle">我们的优势</p>
      <p class="SmallP" style="text-indent: 2em">
        我司成立于1997年，专业从事国际标准管理体系咨询和培训业务，20多年来服务的客户中不乏世界500强企业、大型港台企业、著名的上市公司和服务公司等，客户类型覆盖广泛，目前已成为省内客户及整合一体化体系最多的咨询公司。
      </p>
      <br />
      <br />
      <p class="PTitle">管理体系内部审核步骤：</p>
      <br />
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/60.png"
        class="SonImgW"
      />
      <br />
      <br />
      <br />
      <p class="PTitle LeftBor">ISO50001管理体系审核重点：</p>
      <div class="PList" style="margin-top: 10px">
        <p>1. 相关方的需求和期望实施。</p>
        <p>2. 社会责任管理风险和机遇的识别与应对措施。</p>
        <p>3. 危险源的识别与评价。</p>
        <p>4. 社会责任管理法律法规的识别与合规性评价。</p>
        <p>5. 社会责任目标与实施方案。</p>
        <p>6. 职业健康安全检测的符合性。</p>
        <p>7. 劳工管理的识别与合规性。</p>
        <p>8. 员工工作时长的管理。</p>
        <p>9. 薪酬管理的有效落实。</p>
        <p>10. 不符合的纠正措施。</p>
        <p>12. .....</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openWindow() {
      window.open("https://www.yuntixi.cn", "_blank");
    },
  },
};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>